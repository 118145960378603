import React, { useState } from 'react'
import { graphql } from 'gatsby'

import { IndentDescription } from '../components/IndentDescription'
import { Layout } from '../components/layout'
import Seo from '../components/seo'
import { ProjectOverview } from '../components/ProjectOverview'
import { HeroBanner } from '../components/HeroBanner'
import { Button } from '../components/Button'
import { ContactSection } from '../components/ContactSection'

import * as styles from './portfolio-page.module.scss'

const defaultProjectsToShowCount = 6

const ProjectsPage = ({
  data: {
    markdownRemark: { html, frontmatter },
    allMarkdownRemark,
  },
}) => {
  const [projectsToShowCount, setProjectsToShowCount] = useState(defaultProjectsToShowCount)
  const projectsToShow = allMarkdownRemark.edges.slice(0, projectsToShowCount)
  const availableProjectsCount = allMarkdownRemark.edges.length - projectsToShowCount
  const showMoreProjects = () => {
    if (availableProjectsCount === 0) {
      return
    }
    setProjectsToShowCount(
      projectsToShowCount +
        (availableProjectsCount > defaultProjectsToShowCount ? defaultProjectsToShowCount : availableProjectsCount)
    )
  }

  return (
    <Layout>
      <Seo title={frontmatter.title} description={frontmatter.seoDescription} keywords={frontmatter.seoKeywords} />
      <HeroBanner darkNextSection header={frontmatter.title}>
        {frontmatter.subtitle}
      </HeroBanner>
      <div className={styles.wrapper}>
        <div className={styles.description}>
          <IndentDescription>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </IndentDescription>
        </div>
        <div className={styles.projects}>
          <div className={styles.projectsOverlay} />
          <div className={styles.projectsList}>
            {projectsToShow.map(({ node }) => (
              <ProjectOverview
                key={node.id}
                imageSrc={node.frontmatter.featuredImage}
                url={node.fields.slug}
                title={node.frontmatter.title}
                city={node.frontmatter.city}
                kind={node.frontmatter.kind}
                area={node.frontmatter.area}
                budget={node.frontmatter.budget}
              />
            ))}
          </div>
          {availableProjectsCount > 0 && (
            <Button plain hollow className={styles.showMore} onClick={showMoreProjects}>
              Pokaż więcej
              <span>({availableProjectsCount})</span>
            </Button>
          )}
        </div>
      </div>
      <ContactSection />
    </Layout>
  )
}

export default ProjectsPage

export const projectsQuery = graphql`
  query ProjectsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        seoDescription
        seoKeywords
        title
        subtitle
      }
    }
    allMarkdownRemark(
      sort: { order: [DESC], fields: [frontmatter___createDate] }
      filter: { frontmatter: { templateKey: { eq: "portfolio-project-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            city
            kind
            area
            budget
            featuredImage
          }
        }
      }
    }
  }
`
